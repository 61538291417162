// woocommerce

(function($, window, document) {

  // DOM ready
  $(function() {

    // add +/- buttons to quanity input
    $('<div class="qty-up">+</div><div class="qty-down">-</div>').insertAfter('.quantity input');
    $('.quantity').each(function () {
        var spinner = $(this),
        input = spinner.find('input[type="number"]'),
        btnUp = spinner.find('.qty-up'),
        btnDown = spinner.find('.qty-down'),
        min = input.attr('min') || 1,
        max = input.attr('max') || 99;

        btnUp.click(function () {
            var oldValue = parseFloat(input.val());
            if (oldValue >= max) {
                var newVal = oldValue;
            } else {
                var newVal = oldValue + 1;
            }
            spinner.find("input").val(newVal);
            spinner.find("input").trigger("change");
        });

        btnDown.click(function () {
            var oldValue = parseFloat(input.val());
            if (oldValue <= min) {
                var newVal = oldValue;
            } else {
                var newVal = oldValue - 1;
            }
            spinner.find("input").val(newVal);
            spinner.find("input").trigger("change");
        });

    });

  });

}(window.jQuery, window, document));
